<template>
  <div></div>
</template>

<script>
export default {
  name: 'VbOverride',
  components: {},
  setup() {
    return {}
  },
}
</script>
